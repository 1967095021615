.big-heading {
    font-size: 30px;
    line-height: 1.3em;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;

    @include to(770px) {
        font-size: 28px;
    }

    @include to(720px) {
        font-size: 23px;
    }

    @include to(600px) {
        font-size: 21px;
    }

    @include to(320px) {
        font-size: 20px;
    }
}